let inited = false;
const updateScrollVariables = () => {
  document.documentElement.style.setProperty('--scroll-x', window.scrollX + 'px');
  document.documentElement.style.setProperty('--scroll-y', window.scrollY + 'px');
  const noscroll = document.body.classList.contains('noscroll');
  if (window.scrollY == 0) {
    if (!noscroll) {
      document.body.classList.add('noscroll');
    }
  } else {
    if (noscroll) {
      document.body.classList.remove('noscroll');
    }
  }
  if (!inited) {
    inited = true;
    document.body.classList.add('scrollinited');
  }
};

// Update scroll variables on page load and scroll
window.addEventListener('load', updateScrollVariables);
window.addEventListener('scroll', updateScrollVariables);

(function ($, window) {
  window.alert = function (i) {
    console.warn(i);
  };
  $(document).ready(function () {
    // Table
    $('table:not(.datatabled):not(.dataTable)')
      .addClass('datatabled')
      .each(function () {
        try {
          $(this).DataTable({
            autoWidth: false,
            paging: false,
            ordering: false,
            responsive: !$(this).hasClass('not_responsive'),
            searching: false,
            createdRow: function (row, data, dataIndex) {
              // Set the data-status attribute, and add a class
              $(row)
                .find('[data-row-class]')
                .each(function () {
                  $(this).closest('tr').addClass($(this).attr('data-row-class'));
                });

              $(row)
                .find('[data-col-class]')
                .each(function () {
                  $(this).closest('td').addClass($(this).attr('data-col-class'));
                });
            }
          });
        } catch (e) {}
      });

    // Toggler for the bulk
    $("[name='bulk_edit']:not(.inited)")
      .addClass('inited')
      .each(function () {
        const $it = $(this);
        const $form = $it.closest('form');
        $form.find('.acf-fields').children('div.acf-field').addClass('hidden');
        $form.find('.bulk-toggler').change(function () {
          const $form = $(this).closest('form');
          $form
            .find("[name='acf[" + $(this).attr('data-rel') + "]']")
            .closest('.acf-field')
            .toggleClass('hidden');
        });
      });
  });
})(jQuery, window);
